<template>
  <v-card class="pa-5">
    <v-card-title class="justify-center">
      <v-img
        lazy-src="@/assets/modal/warning.svg"
        src="@/assets/modal/warning.svg"
        max-width="250"
      />
    </v-card-title>
    <v-card-text class="text-center">
      <h5 class="text-h5">
        {{ $store.state.modal.text }}
      </h5>
      <small
        v-if="$store.state.modal.caption !== null"
        class="text-body-2"
      >
        {{ $store.state.modal.caption }}
      </small>
    </v-card-text>
    <v-card-actions class="justify-center">
      <v-radio-group
        column
        v-model="action"
      >
        <v-radio
          label="Adicionar esta gravação aos produtos selecionados"
          value="add"
        />
        <v-radio
          label="Remover esta gravação dos produtos selecionados"
          value="remove"
        />
      </v-radio-group>
    </v-card-actions>
    <v-card-actions class="justify-center">
      <v-btn
        color="primary"
        rounded
        outlined
        @click="negate"
      >
        Não
      </v-btn>
      <v-btn
        color="primary"
        rounded
        filled
        @click="confirm"
      >
        Sim
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      action: 'add',
    }
  },
  methods: {
    negate() {
      this.$store.state.modal.negate()
    },
    confirm() {
      this.$store.state.modal.confirm(this.action)
    },
  },
}
</script>