<template>
  <v-card>
    <v-stepper flat v-model="step">
      <v-stepper-items>
        <v-stepper-content step="1">
          <v-card-title class="justify-center">
            <v-img
              lazy-src="@/assets/modal/warning.svg"
              src="@/assets/modal/warning.svg"
              max-width="250"
            />
          </v-card-title>
          <v-card-text class="text-center">
            <h5 class="text-h5">
              {{ $store.state.modal.text }}
            </h5>
            <small
              v-if="$store.state.modal.caption !== null"
              class="text-body-2"
            >
              {{ $store.state.modal.caption }}
            </small>
            <v-combobox
              v-model="email_selected"
              :items="emails"
              class="py-7"
              :item-text="'title'"
              :item-value="'email'"
              label="Email"
              multiple
              outlined
              dense
            ></v-combobox>
          </v-card-text>

          <v-card-actions class="justify-center">
            <v-btn
              color="primary"
              rounded
              outlined
              @click="negate"
            >
              Cancelar
            </v-btn>
            <v-btn
              rounded
              color="primary"
              @click="step = 2"
              :disabled="email_selected.length === 0"
            >
              Próximo
            </v-btn>
          </v-card-actions>
        </v-stepper-content>

        <v-stepper-content step="2">
          <v-card-actions class="d-block">
            <label class="subtitle-2 secondary--text">Corpo do Email:</label>
            <ckeditor
              v-model="body"
              id="richText"
              :config="editorConfig"
              rounded
              dense
              outlined
            />
          </v-card-actions>
          <v-card-actions class="justify-center">
            <v-btn
              color="primary"
              rounded
              outlined
              @click="step = 1"
            >
              Voltar
            </v-btn>
            <v-btn
              color="primary"
              rounded
              filled
              @click="confirm"
              :loading="loading"
            >
              Enviar
            </v-btn>
          </v-card-actions>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </v-card>
</template>

<script>

import { mapGetters } from 'vuex'

export default {
  data: () => ({
    step: 1,
    emails: [],
    body: null,
    email_selected: [],
    editorConfig: {
      toolbar: [['Bold', 'Italic', 'Strike', 'Styles', 'Format']],
    },
  }),
  computed: {
    ...mapGetters({
      loading: 'getSendEmailLoading'
    })
  },
  methods: {
    negate() {
      this.$store.state.modal.negate()
    },
    confirm() {
      this.$store.state.modal.confirm({
        emails: this.email_selected,
        body: this.body,
      })
    },
    getEmails() {
      this.$api
        .post(`client/emails`, {
          client_id: this.$store.state.modal.client_id,
        })
        .then((res) => {
          this.emails = res.data.data
        })
        .catch((err) => {
          console.log(err)
        })
    },
  },
  mounted() {
    this.getEmails()
  },
}
</script>
<style scoped>
#richText div.cke_contents {
  height: auto !important;
}
#richText iframe.cke_wysiwyg_frame {
  max-height: 100px !important;
}
#richText .cke_bottom {
  display: none;
}
</style>
