<template >
  <v-card
    class="pa-5"
    style="font-size: 80%"
  >
    <v-card-title class="justify-center">
      <v-img
        lazy-src="@/assets/modal/warning.svg"
        src="@/assets/modal/warning.svg"
        max-width="250"
      />
    </v-card-title>
    <v-card-text class="text-center">
      <h5 class="text-h5">
        {{ $store.state.modal.text }}
      </h5>
      <small
        v-if="$store.state.modal.caption !== null"
        class="text-body-2"
      >
        {{ $store.state.modal.caption }}
      </small>
    </v-card-text>

    <!--    BODY DO MODAL -->
    <v-card-actions>
      <div style="width: 100%">
        <v-row
          cols="12"
          lg="12"
        >
          <v-col
            align="center"
            cols="12"
            md="2"
            >Usuário</v-col
          >
          <v-col
            align="center"
            cols="12"
            md="5"
            >Status</v-col
          >
          <v-col
            align="center"
            cols="12"
            md="2"
            >Criado em</v-col
          >
          <v-col
            align="center"
            cols="12"
            md="2"
            >Obs.</v-col
          >
        </v-row>
        <hr style="border-color: #ff9900" />
        <v-row
          class="mt-2"
          v-for="(history, idx) in log"
          :key="idx"
        >
          <v-row
            class="pa-2"
            align="center"
            cols="12"
            lg="12"
          >
            <v-col
              align="left"
              cols="12"
              md="2"
              >{{ history.user_name }}</v-col
            >
            <v-col
              align="right"
              cols="12"
              md="5"
              >{{ history.order_status }}</v-col
            >
            <v-col
              align="right"
              cols="12"
              md="2"
              >{{ history.created_at }}</v-col
            >
            <v-col
              align="right"
              cols="12"
              md="2"
            >
              <v-btn
                color="primary"
                rounded
                filled
                @click="getLogObservation(history.id)"
                icon
              >
                <v-icon>fas fa-comments</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <hr style="opacity: 25%" />
        </v-row>
      </div>
    </v-card-actions>

    <v-card-actions class="justify-center pa-4">
      <!-- <v-btn
                color="primary"
                v-text="'Não'"
                rounded
                outlined
                @click="negate"
            /> -->
      <v-btn
        color="primary"
        rounded
        filled
        @click="confirm"
      >
        Sair
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  data: () => ({
    log: [],
    obs: null,
  }),
  computed: {},
  methods: {
    negate() {
      this.$store.state.modal.negate()
    },
    confirm() {
      this.$store.state.modal.confirm()
    },
    getHistory() {
      let id = this.$store.state.modal.id

      this.$api
        .post(`order/log`, { order_id: id })
        .then((res) => {
          this.log = res.data.data
        })
        .catch((err) => {
          console.log(err)
        })
    },
    getLogObservation(id) {
      this.$api
        .post(`order/log_observation`, { id: id })
        .then((res) => {
          this.obs = res.data.data
          this.show_modal()
        })
        .catch((err) => {
          console.log(err)
        })
    },
    show_modal() {
      this.$store.commit('setModal', {
        show: true,
        component: 'success',
        text: 'Observação',
        caption: '' + this.obs,
        hasObs: true,
        confirm: () => {
          this.$store.commit('setModal', {
            show: false,
          })
        },
      })
    },
  },
  mounted() {
    this.getHistory()
  },
}
</script>