<template>
  <v-dialog
    v-model="$store.state.modal.show"
    transition="dialog-top-transition"
    max-width="600"
    persistent
  >
    <template>
      <component :is="$store.state.modal.component" />
    </template>
  </v-dialog>
</template>
<script>
import Success from './SuccessModal.vue'
import Error from './ErrorModal.vue'
import Warning from './WarningModal.vue'
import Cancel from './CancelModal.vue'
import SendEmail from './SendEmailModal.vue'
import Order from './OrderModal.vue'
import Radio from './RadioModal.vue'

export default {
  components: {
    Success,
    Error,
    Warning,
    Cancel,
    SendEmail,
    Order,
    Radio,
  },
}
</script>